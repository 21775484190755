import React, {useContext} from 'react';
import {useStaticQuery, graphql} from "gatsby";
import Img from "gatsby-image";
import {GlobalDispatchContext, GlobalStateContext} from "../context/GlobalContextProvider";
import FlyerUebersichtAlarm2023 from '../images/Publications/Flyer/flyer-uebersicht-alarm';
import FlyerUebersichtSpiele2023 from '../images/Publications/Flyer/flyer-uebersicht-spiele';
import BroStuUeb from '../images/Publications/Flyer/broschuere-studie-ueberblick';
// import Plakat1 from '../images/Publications/Flyer/Plakat1';
import Www1P from '../images/Publications/Flyer/www1';
import Www2P from '../images/Publications/Flyer/www2';
import FiwiP from '../images/Publications/Flyer/fiwi';
import FiwiP2 from '../images/Publications/Flyer/fiwi2';
//import Flyer1 from '../images/Publications/Flyer/Flyer1';
import Flyer2 from '../images/Publications/Flyer/Flyer2';
import MAZ202305 from '../images/Publications/Press/2023-05-maz';
import THNews202307 from '../images/Publications/Press/2023-07-nachlese';
import THNews202306 from '../images/Publications/Press/2023-06-th-news';
import ReginaInterview2022 from '../images/Publications/Press/2022-10-interview';
import ReginaInterview2023 from '../images/Publications/Press/2023-06-interview';
import RBBAktuell2022 from '../images/Publications/Press/2022-11-rbb-aktuell';
import MargitInterview2022 from "../images/Publications/Press/2022-11-interview";
/* import PressTisimImage from "../images/Publications/Press/tisim.inline.svg"; */
/* import Maz2203Press from "../images/Publications/Press/2022-03-maz"; */
import TH202006 from '../images/Publications/Press/2020-06-th';
import MAZ202006 from '../images/Publications/Press/2020-06-maz';
/* import TisimBlog202207 from '../images/Publications/Press/2022-07-tisim'; */

import SPRINGER2023MS  from '../images/Publications/Scientific/SPRINGER2023MS';
import ReportDrei2023Final from '../images/Publications/Scientific/report3-final';
import REPORT02 from '../images/Publications/Scientific/RePORT02';
import SPRINGER2021 from '../images/Publications/Scientific/SPRINGER2021';
import IvmSGsudIdMe17 from '../images/Publications/Scientific/IvmSGsudIdMe17';
import QWSAiKMUTG from '../images/Publications/Scientific/QWSAiKMUTG';
import RainerRG from '../images/Publications/Scientific/RainerResearchGate';
import RzIKMUST from '../images/Publications/Scientific/RzIKMUST';
import QWSAKMiU from '../images/Publications/Scientific/QWSAKMiU';
import IJETAE2021 from '../images/Publications/Scientific/IJETAE2021';
// import TargetGroups from  '../images/Publications/Scientific/TargetGroups';
import DLINE021 from '../images/Publications/Scientific/DLINE021';
import REPORT01 from '../images/Publications/Scientific/RePORT01';
import JtHWTHWR from '../images/Publications/Scientific/JtHWRHWT';
import Lissabon2022 from '../images/Publications/Scientific/Lissabon2022';
import IADIS2022 from '../images/Publications/Scientific/IADIS2022';
import Jitst2022 from '../images/Publications/Scientific/Jitst2022';
import Hicss2023Paper from '../images/Publications/Scientific/Hicss2023Paper';
import Hicss2023Praesi from '../images/Publications/Scientific/Hicss2023Praesi';
import StudieDrei2023 from '../images/Publications/Scientific/2023Studie3';
import StudieDrei2023en from '../images/Publications/Scientific/2023Studie3en';
import Forum2023 from '../images/Publications/Activities/2023-06-23-forum';
import CyberSecTag2023 from '../images/Publications/Activities/2023-06-29-cyber-security-tag';
import AwarenessPotsdam2024 from '../images/Publications/Activities/2024-01-31-awarenes-potsdam';
import AwarenessStuttgart2024 from '../images/Publications/Activities/2024-03-22-awarenes-stuttgart';
import AwarenessBerlin2024 from '../images/Publications/Activities/2024-02-19-awarenes-berlin';
import LiceWcsne2022 from '../images/Publications/Scientific/lice-wcsne';
import CPSTR2023 from '../images/Publications/Scientific/scholl-2023';
import CPSTR2024 from '../images/Publications/Scientific/scholl-2024';
import ABSCHLUSS2024 from '../images/Publications/Scientific/abschluss';
import SCHLUSS2024 from '../images/Publications/Scientific/schlussbericht';
import Resuemee2024  from '../images/Publications/Scientific/2024Resuemee';
import Podcast2024A from '../images/Publications/Press/2024-podcast-a';
import Podcast2024B from '../images/Publications/Press/2024-podcast-b';
import Podcast2024C from '../images/Publications/Press/2024-podcast-c';
import Podcast2024D from '../images/Publications/Press/2024-podcast-d';
import Podcast2024E from '../images/Publications/Press/2024-podcast-e';
import Podcast2024G from '../images/Publications/Press/2024-podcast-g';
import DABDigitalforum2023 from '../images/Publications/Activities/2023-10-11-dab-digitalforum';
import ZITAwareness2023 from '../images/Publications/Activities/2023-11-27-zit-awareness';
import HWKPotsdam2023 from '../images/Publications/Activities/2023-12-07-hwk-potsdam';
import Impressionen from '../images/Publications/Activities/impressionen';

//import Weizenbaum202009Elite from '../images/Publications/Activities/2020-09-weizenbaumElite';
//import Weizenbaum202009Workshop from '../images/Publications/Activities/2020-09-weizenbaum-workshop';
const PublicationsBlurp = () => {

    const dispatch = useContext(GlobalDispatchContext);
    const state = useContext(GlobalStateContext);

    const pressHidden = state.pressId + " hidden";
 
    //const eventsHidden = state.eventsId + " hidden";
    //const flyerHidden = state.flyerId + " hidden";
    //const networkingHidden = state.networkingId + " hidden";
    const publicationsHidden = state.publicationsId + " hidden";
    const data = useStaticQuery(graphql`
        query {
            arrow: file(relativePath: { eq: "ui-arrow.png" }) {
                childImageSharp {
                    fluid(maxWidth: 74, quality: 50) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            pressPodcast: file(relativePath: { eq: "Publications/Press/podcast-icon.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            
            pressAlarmFirstResults: file(relativePath: { eq: "Publications/Press/Presse-VorstellungErsterErgebnisse.png"}) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            pressHackerJuly21pdf: file(relativePath: { eq: "Publications/Press/Presse-Juli21.pdf" }) {
                name
                publicURL
            }
            pressHackerJuly21: file(relativePath: { eq: "Publications/Press/Hacker-Coverimage.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            networkingITWirtschaft: file(relativePath: { eq: "Publications/Networking/logo-it-wirtschaft.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            networkingKompetenzzentrum: file(relativePath: { eq: "Publications/Networking/KompetenzzentrumCottbus.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            networkingFrauenhofer: file(relativePath: { eq: "Publications/Networking/FrauenhoferFokus.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 50) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            networkingITsBB: file(relativePath: { eq: "Publications/Networking/itsbb.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            networkingcybersicher: file(relativePath: { eq: "Publications/Networking/cybersicher.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            networkingForum: file(relativePath: { eq: "Publications/Networking/forum.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            wisswoche: file(relativePath: { eq: "Publications/Press/wiss-woche.pdf"}) {
                name
                publicURL
            }
            wisswocheimg: file(relativePath: { eq: "Publications/Press/wiss-woche.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            networkingForum23: file(relativePath: { eq: "Publications/Press/2023awarenessforum.pdf"}) {
                name
                publicURL
            }

            scientificResearchGate: file(relativePath: { eq: "Publications/Scientific/research-gate.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 50) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            
            moderatoren23: file(relativePath: { eq: "Publications/Activities/2023-moderatoren-einladung.pdf"}) {
                name
                publicURL
            }
            interview2020: file(relativePath: { eq: "Publications/Media/interview-rbb-29-11-2020.mp3"}) {
                name
                publicURL
            }
            forum22Image: file(relativePath: { eq: "Publications/Activities/cal-2022-04-08.jpg" }) {
                childImageSharp {
                    fluid(maxHeight: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            weizenbaumImage: file(relativePath: { eq: "Publications/Activities/cal-2022-09-21.jpg" }) {
                childImageSharp {
                    fluid(maxHeight: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            moderatorenImage: file(relativePath: { eq: "Publications/Activities/cal-2023-01-12.jpg" }) {
                childImageSharp {
                    fluid(maxHeight: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
   
            htwImage: file(relativePath: { eq: "Publications/Activities/cal-2022-09-08.jpg" }) {
                childImageSharp {
                    fluid(maxHeight: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            dresdenImage: file(relativePath: { eq: "Publications/Activities/cal-2022-09-29.jpg" }) {
                childImageSharp {
                    fluid(maxHeight: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            activityWeizenbaumWorkshopPDF: file(relativePath: { eq: "Publications/Activities/22-23-9-Workshop_Weizenbaum_ELITE_final-1.pdf"}) {
                name
                publicURL
            }
            activityWeizenbaumElitePDF: file(relativePath: { eq: "Publications/Activities/21-9-Weizenbaum_ELITE_Abend_2022-1.pdf" }) {
                name
                publicURL
            }
        }
    `)

    /*
        <button onClick={() => dispatch({type: "CHANGE_PUB_PRESS"})}>Presse</button>
        <button onClick={() => dispatch({type: "CHANGE_PUB_ACTIVITIES"})}>Aktivitäten</button>
        <button onClick={() => dispatch({type: "CHANGE_PUB_EVENTS"})}>Veranstaltungen</button>
        <button onClick={() => dispatch({type: "CHANGE_PUB_FLYER"})}>Flyer & Broschüren</button>
        <button onClick={() => dispatch({type: "CHANGE_PUB_NETWORKING"})}>Vernetzung</button>
        <button onClick={() => dispatch({type: "CHANGE_PUB_PUBLICATIONS"})}>Wiss. Publikationen</button>
    */


    return(
        <div className="publications-blurb">
            <span className="anchor" id="publications">anchor</span>
            <div className="container">
                <div className="content">
                    <h3>Öffentlichkeits&shy;arbeit</h3>
                    <div className="oeffPpara">
                        Im Rahmen des dreijährigen Projektes werden unterschiedliche 
                        Veröffentlichungen über die Projektergebnisse erstellt, 
                        auf die an dieser Stelle sukzessive hingewiesen werden wird.
                    </div>
                    <div className="buttons">
                        <button onClick={() => dispatch({type: "CHANGE_PUB_PRESS"})}>Presse</button>
                        <button onClick={() => dispatch({type: "CHANGE_PUB_EVENTS"})}>Veranstaltungen</button>
                        <button onClick={() => dispatch({type: "CHANGE_PUB_FLYER"})}>Flyer & Broschüren</button>
                        <button onClick={() => dispatch({type: "CHANGE_PUB_NETWORKING"})}>Vernetzung</button>
                        <button onClick={() => dispatch({type: "CHANGE_PUB_PUBLICATIONS"})}>Wiss. Publikationen</button>
                    </div>
                </div>
                <div className="rows">
                    <div className="content">
                        <h3>Öffentlichkeits&shy;arbeit</h3>
                        <p>Im Rahmen des dreijährigen Projektes werden unterschiedliche Veröffentlichungen über die Projektergebnisse erstellt, auf die an dieser Stelle sukzessive hingewiesen werden wird.</p>
                        <div className="buttons">
                            <button onClick={() => dispatch({type: "CHANGE_PUB_PRESS"})}>Presse</button>
                            <button onClick={() => dispatch({type: "CHANGE_PUB_EVENTS"})}>Veranstaltungen</button>
                            <button onClick={() => dispatch({type: "CHANGE_PUB_FLYER"})}>Flyer & Broschüren</button>
                            <button onClick={() => dispatch({type: "CHANGE_PUB_NETWORKING"})}>Vernetzung</button>
                            <button onClick={() => dispatch({type: "CHANGE_PUB_PUBLICATIONS"})}>Wiss. Publikationen</button>
                        </div>
                    </div>
					<Podcast2024G state={state}/>
                    <Podcast2024A state={state}/>
                    <Podcast2024B state={state}/>
                    <Podcast2024C state={state}/>
                    <Podcast2024D state={state}/>
                    <Podcast2024E state={state}/>
                    <div className={state.pressId}>
                        <div className="box">
                            <div className="image-container">
                                <a href={data.wisswoche.publicURL} target="_blank" rel="noreferrer">
                                    <Img fluid={data.wisswocheimg.childImageSharp.fluid} className="image"/> 
                                </a>
                            </div>
                            <p>Wissenschaftswoche</p>
                            <p className="subtitle">Plakat (PDF)</p>
                        </div>
                    </div>
                    
                    <THNews202307 state={state}/>
                    <THNews202306 state={state}/>
                    <MAZ202305 state={state}/>
                    <ReginaInterview2023 state={state}/>
                    <ReginaInterview2022 state={state}/>
                    <RBBAktuell2022 state={state}/>
                    <MargitInterview2022 state={state}/>
{/*                     <TisimBlog202207 state={state}/>
                    <div className={state.pressId}>
                        <div className="box">
                            <div className="paper-container">
                                <div className="image-container">
                                    <a href="https://www.tisim.de/informationssicherheit-muss-gelebt-werde/" 
                                    target="_blank" rel="noreferrer">
                                    <div className="image-container">
                                        <PressTisimImage className="image svg"/>
                                    </div>
                                    </a>
                                </div>
                                <div className="outside">
                                    <div className="inside">  
                                        <p>Informationssicherheit <br/>muss gelebt werden!</p>
                                        <p className="subtitle">
                                                <a href="https://www.tisim.de/informationssicherheit-muss-gelebt-werde/"  
                                                target="_blank" rel="noreferrer" 
                                                aria-label="Link zur website  des TISIM-Projektes">
                                                Link zur Website
                                                </a>
                                                </p>
                                                <p className="subtitle">
                                            März 2022
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Maz2203Press state={state}/>
 */}
                    
                    <div className={state.pressId}>
                        <div className="box">
                            <div className="image-container">
                                <a href={data.pressHackerJuly21pdf.publicURL} target="_blank" rel="noreferrer">
                                    <Img fluid={data.pressHackerJuly21.childImageSharp.fluid} className="image"/> 
                                </a>
                            </div>
                            <p>Gegen Hacker-Angriffe gewappnet</p>
                            <p className="subtitle">Pressemitteilung der MAZ</p>
                        </div>
                    </div>
                    <div className={state.pressId}>
                        <div className="box">
                            <div className="image-container">
                                <a href="https://www.th-wildau.de/hochschule/aktuelles/neuigkeiten/news/informationssicherheit-in-unternehmen-vorstellung-erster-ergebnisse-des-th-wildau-projektes-alarm/" target="_blank" rel="noreferrer">
                                    <Img fluid={data.pressAlarmFirstResults.childImageSharp.fluid} className="image"/> 
                                </a>
                            </div>
                            <p>ALARM erste Ergebnisvorstellung</p>
                            <p className="subtitle">Pressemitteilung der TH Wildau</p>
                        </div>
                    </div>
                    <div className={state.pressId}>
                        <div className="box">
                            <div className="image-container small-image">
                                <Img fluid={data.pressPodcast.childImageSharp.fluid} 
                                className="image medium"/> 
                                <audio src="/interview-rbb-29-11-2020.mp3" 
                                       type="audio/mpeg"
                                controls>Audio filetype not supported by browser
                                <track />
                                </audio>
                            </div>
                            <p>Interview 29.11.2020</p>
                            <p className="subtitle">Zwischen Margit Scholl und dem RBB</p>
                        </div>
                    </div>
                    <MAZ202006 state={state}/>
                    <TH202006 state={state}/>
                  
                    {/* <div className={pressHidden}>
                        <div className="box">
                            <div className="image-container">

                            </div>
                            <p>Presse E</p>
                            <p className="subtitle">Kurzbeschreibung E</p>                       
                        </div>
                    </div>
                    <div className={pressHidden}>
                        <div className="box">
                            <div className="image-container">

                            </div>
                            <p>Presse E</p>
                            <p className="subtitle">Kurzbeschreibung E</p>                       
                        </div>
                    </div> */}
                    <div className={pressHidden}>
                        <div className="box">
                            <div className="image-container">

                            </div>
                            <p>Presse E</p>
                            <p className="subtitle">Kurzbeschreibung E</p>                       
                        </div>
                    </div>
                    <Impressionen state={state}/>  
                    <AwarenessStuttgart2024 state={state}/>  
                    <AwarenessBerlin2024 state={state}/>  
                    <AwarenessPotsdam2024 state={state}/>
                    <HWKPotsdam2023 state={state}/>
                    <ZITAwareness2023  state={state}/> 
                    <DABDigitalforum2023 state={state}/> 
                    <CyberSecTag2023 state={state}/>              
                    <Forum2023 state={state}/>
                    <div className={state.eventsId}>
                        <div className="box">
                            <div className="paper-container">
                            <div className="calendar">
                                    <div className="outside">
                                        <div className="inside">         
                                            <p className="datum">11.05-12.05.2023</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="outside">
                                    <div className="inside">
                                        <p>Take Aware / Sexy Security 2023 <br />
                                        Strangers in the Night </p>
                                        <p>
                                            <a href="https://www.linkedin.com/feed/update/urn:li:activity:7031186291283116032"
                                            target="_blank" rel="noreferrer">Regina Schuktumow <br/>zur Take Aware</a>
                                        </p>
                                        <p>
                                            <a href="https://www.linkedin.com/feed/update/urn:li:activity:7057296387155062787"
                                            target="_blank" rel="noreferrer">Hubertus v. Tippelskirch: <br/>Serious Games für starke Interaktion in KMU</a>
                                        </p>
                                    </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                   {/*  <div className={pressHidden}>
                        <div className="box">
                            <div className="image-container">

                            </div>
                            <p>Presse E</p>
                            <p className="subtitle">Kurzbeschreibung E</p>                       
                        </div>
                    </div>
                    <div className={pressHidden}>
                        <div className="box">
                            <div className="image-container">

                            </div>
                            <p>Presse F</p>
                            <p className="subtitle">Kurzbeschreibung F</p>
                        </div>
                    </div> */}
                    
                    <div className={state.eventsId}>
                        <div className="box">
                            <div className="paper-container">
                            <div className="calendar">
                                    <div className="outside">
                                        <div className="inside">         
                                            <p className="datum">12.01.2023</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="outside">
                                    <div className="inside">
                                        <p>Moderarorenausbildung  <br />
                                        an derTechnischen Hochschule Wildau <br />
                                            (in Präsenz)</p>
                                        <p>
                                            <a href={data.moderatoren23.publicURL} 
                                            target="_blank" rel="noreferrer">Einladung</a>
                                        </p>
                                    </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div className={state.eventsId}>
                        <div className="box">
                            <div className="paper-container">
                                <div className="calendar">
                                    <div className="outside">
                                        <div className="inside">         
                                            <p className="datum">29.09.2022</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="outside">
                                    <div className="inside">   
                                    <p>
                                        Kongresszentrum Dresden<br/>
                                        32. Cyber-Sicherheits-Tag<br/>
                                        Allianz für Cyber-Sicherheit, BSI
                                    </p>
                                    <p className="subtitle">
                                        
                                    </p>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className={state.eventsId}>
                        <div className="box">
                            <div className="paper-container">
                                <div className="calendar">
                                    <div className="outside">
                                        <div className="inside">         
                                            <p className="datum">21.09.2022</p>
                                        </div>
                                    </div>
                                </div>                          
                                <div className="outside">
                                    <div className="inside">                                
                                        <p>Aktionstage IT-Sicherheit</p>
                                        <p>"Angriff aufs System. Was nun? – Herausforderungen, Chancen und Aussichten für IT-Sicherheit in KMU"</p>
                                        <p className="subtitle">
                                            <a href={data.activityWeizenbaumElitePDF.publicURL} target="_blank" rel="noreferrer">
                                            Eröffnung 21.09.2022</a>
                                        </p>
                                        <p className="subtitle">
                                            <a href={data.activityWeizenbaumWorkshopPDF.publicURL} target="_blank" rel="noreferrer">
                                            Aktionstage 22.-23.09.2022</a>
                                        </p>
                                    </div> 
                                </div>     
                            </div> 
                        </div>
                    </div>
                    <div className={state.eventsId}>
                        <div className="box">
                            <div className="paper-container">
                            <div className="calendar">
                                    <div className="outside">
                                        <div className="inside">         
                                            <p className="datum">08.09.2022</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="outside">
                                    <div className="inside">                                
                                        <p>HTW Berlin</p>
                                        <p>11. IT-Sicherheitstag <br />Mittelstand</p>
                                        <p className="subtitle" >
                                                                                   </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={state.eventsId}>
                        <div className="box">
                            <div className="paper-container">
                            <div className="calendar">
                                    <div className="outside">
                                        <div className="inside">         
                                            <p className="datum">08.04.2022</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="outside">
                                    <div className="inside"> 
                                        <p>Awareness Forum 2022</p>
                                        <p className="subtitle" >
                                            <a
                                                href="
                                                https://www.th-wildau.de/hochschule/aktuelles/neuigkeiten/news/1-awareness-forum-an-der-th-wildau-stellt-it-bedrohungs-und-abwehrszenarien-in-den-mittelpunkt/"  
                                                target="_blank" rel="noreferrer" 
                                                aria-label="Link zum Beitrag über das Awarness-Forum">
                                                Pressemitteilung
                                            </a>
                                        </p>
                                    </div>
                                </div>        
                            </div>
                        </div>
                    </div>                   
                    <div className={state.eventsId}>
                        <div>
                            <div className="image-container">

                            </div>
                           
                            <p className="subtitle"></p>
                        </div>
                    </div>
                    <div className={state.eventsId}>
                        <div>
                            <div className="image-container">

                            </div>
                           
                            <p className="subtitle"></p>
                        </div>
                    </div>
                    <div className={state.eventsId}>
                        <div>
                            <div className="image-container">

                            </div>
                           
                            <p className="subtitle"></p>
                        </div>
                    </div>
                    {/* Liste der Flyer- und Plakate */}
                    <Flyer2 state={state} />
                    <BroStuUeb state={state} />
                    <FlyerUebersichtAlarm2023 state={state} />

                    <Www1P state={state} />
                    <Www2P state={state} />
                    <FiwiP state={state} />
                    <FiwiP2 state={state} />
                    <FlyerUebersichtSpiele2023 state={state} />
                                       
                    {/* <Plakat1 state={state} /> */}
                   
                    <div className={state.flyerId}>
                        <div>
                            <div className="image-container">
                            </div>

                            <p className="subtitle"></p>
                        </div>
                    </div>
                    <div className={state.flyerId}>
                        <div>
                            <div className="image-container">
                            </div>

                            <p className="subtitle"></p>
                        </div>
                    </div>
                    <div className={state.flyerId}>
                        <div>
                            <div className="image-container">
                            </div>

                            <p className="subtitle"></p>
                        </div>
                    </div>

                    <div className={state.networkingId}>
                        <div className="box">
                            <div className="image-container">
                                <a  href={data.networkingForum23.publicURL}
                                    target="_blank" rel="noreferrer">
                                <Img fluid={data.networkingForum.childImageSharp.fluid} 
                                     className="image"/> 
                                </a>
                            </div>
                            <p>
                                Forum 2023<br />
                                Impressionen  <br />
                            </p>
                        </div>
                    </div>
                    
                    {/* <div className={state.networkingId}>
                        <div className="box">
                            <div className="image-container small-image">
                                <a href="https://itwirtschaft.de/" target="_blank" rel="noreferrer" aria-label="Link zur Website IT-Wirtschaft">
                                    <Img fluid={data.networkingForum.childImageSharp.fluid} className="image small"/>
                                </a>
                            </div>
                            <p>Forum 2023<br />
                            Impressionen  <br /><br />
                            </p>
                        </div>
                    </div> */}
                    <div className={state.networkingId}>
                        <div className="box">
                            <div className="image-container small-image">
                                <a href="https://itwirtschaft.de/" target="_blank" rel="noreferrer" aria-label="Link zur Website IT-Wirtschaft">
                                    <Img fluid={data.networkingITWirtschaft.childImageSharp.fluid} className="image small"/>
                                </a>
                            </div>
                            <p>Mittelstand 4.0  <br />
                                Kompetenzzentrum  <br />
                                IT-Wirtschaft
                            </p>
                        </div>
                    </div>
                    <div className={state.networkingId}>
                        <div className="box">
                            <div className="image-container small-image"> 
                                <a href="https://www.th-wildau.de/forschung-transfer/ic3/kompetenzzentrum-cottbus/" target="_blank" rel="noreferrer" aria-label="Link zur Website IT-Wirtschaft">
                                    <Img fluid={data.networkingKompetenzzentrum.childImageSharp.fluid} className="image small"/>
                                </a>
                            </div>
                            <p>
                                Mittelstand 4.0 <br />
                                Kompetenzzentrum  <br />
                                Cottbus HUB Wildau
                            </p>
                        </div>
                    </div>
                    <div className={state.networkingId}>
                        <div className="box">
                            <div className="image-container">
                                <a href="https://www.fokus.fraunhofer.de/de/espri/ueber_espri" target="_blank" rel="noreferrer" aria-label="Link zur Website Fokus Frauenhofer">
                                    <Img fluid={data.networkingFrauenhofer.childImageSharp.fluid} className="image"/> 
                                </a>
                            </div>
                            <p><br/>Frauenhofer FOKUS</p>
                        </div>
                    </div>
                    <div className={state.networkingId}>
                        <div className="box">
                            <div className="image-container">
                                <a href="https://www.itsbb.net/mitglieder/technische-hochschule-wildau/" target="_blank" rel="noreferrer" aria-label="Link zur Website it's.BB">
                                    <Img fluid={data.networkingITsBB.childImageSharp.fluid} className="image"/> 
                                </a>
                            </div>
                            <p>Netzwerk für IT-Sicherheit in der Hauptstadtregion Berlin-Brandenburg</p>
                        </div>
                    </div>
                    <div className={state.networkingId}>
                        <div className="box">
                            <div className="image-container">
                                <a href="https://transferstelle-cybersicherheit.de" target="_blank" rel="noreferrer" aria-label="Link zur Website it's.BB">
                                    <Img fluid={data.networkingcybersicher.childImageSharp.fluid} className="image"/> 
                                </a>
                            </div>
                            <p>Cybersicherheit für den Mittelstand</p>
                         </div>
                    </div>
                    <div className={state.networkingId}>
                            <div className="image-container">
                              
                            </div>
                            <p></p>
                            <p className="subtitle"></p>
                    </div>
                    
                    <div className={state.networkingId}>
                    <div>
                            <div className="image-container">

                            </div>
                           
                            <p className="subtitle"></p>
                        </div>
                    </div>
                    <div className={state.networkingId}>
                 
                            <div className="image-container">

                            </div>
                            <p></p>
                            <p className="subtitle"></p>
                     
                    </div>
                    {/* <div className={state.networkingId}>
                    <div>
                            <div className="image-container">

                            </div>
                           
                            <p className="subtitle"></p>
                        </div>
                    </div>
                    <div className={state.networkingId}>
                    <div>
                            <div className="image-container">

                            </div>
                           
                            <p className="subtitle"></p>
                        </div>
                    </div>
                    <div className={state.networkingId}>
                    <div>
                            <div className="image-container">

                            </div>
                           
                            <p className="subtitle"></p>
                        </div>
                    </div> */}
                    <SCHLUSS2024 state={state} />
                    <Resuemee2024  state={state}/>
                    <ABSCHLUSS2024 state={state} />
                    <CPSTR2024 state={state} />
                    <CPSTR2023 state={state} />
                    <RainerRG state={state} />
                   
                    <SPRINGER2023MS state={state} />
                    <Hicss2023Praesi state={state} />
                    <Hicss2023Paper state={state} />
                    <IvmSGsudIdMe17 state={state} />
                    <ReportDrei2023Final state={state}/>
                   
                    <StudieDrei2023en state={state}/>
                    <StudieDrei2023 state={state}/>
                    <Jitst2022 state={state} />
                    <LiceWcsne2022 state={state} />  
                    {/* <TargetGroups state={state} /> */}
                    <Lissabon2022 state={state} />
                   
                    {/* Vorletzte Reihe */}
                    <IADIS2022 state={state} />  
                    <JtHWTHWR state={state} /> 
                    <REPORT02 state={state} />
                    <RzIKMUST state={state} />
                    <QWSAKMiU state={state} />
                    
                    {/* Letzte Reihe */}
                    <QWSAiKMUTG state={state} />
                    <REPORT01 state={state} />         
                    <IJETAE2021 state={state} />
                    <SPRINGER2021 state={state} />
                    <DLINE021 state={state} />
                  
                    <div className={publicationsHidden}>
                        <div className="box">
                            <div className="image-container">

                            </div>
                            <p>Wiss. Veröffentlichung  D</p>
                            <p className="subtitle">Kurzbeschreibung D</p>
                        </div>
                    </div>
                    <div className={publicationsHidden}>
                        <div className="box">
                            <div className="image-container">

                            </div>
                            <p>Wiss. Veröffentlichung  D</p>
                            <p className="subtitle">Kurzbeschreibung D</p>
                        </div>
                    </div>
                    <div className={publicationsHidden}>
                        <div className="box">
                            <div className="image-container">

                            </div>
                            <p>Wiss. Veröffentlichung  D</p>
                            <p className="subtitle">Kurzbeschreibung D</p>
                        </div>
                    </div>
                </div>
                <div className="ui-arrows">
                    <div className="image left">
                    <Img fluid={data.arrow.childImageSharp.fluid} className="left"/>
                    </div>
                    <div className="image right">
                    <Img fluid={data.arrow.childImageSharp.fluid} className="right"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PublicationsBlurp;